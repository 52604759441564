import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import axios from "../Utils/Axios";
import { ErrorHandler } from '../ui/ErrorHandler';
import { useAlert } from "react-alert";

function AttributeSelectorEdit(props) {
    const alert = useAlert();
    const [attributeValues, setAttributeValues] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [attributeId, setAttributeId] = useState(props.attributeId ?? 0);

    const [loading, setLoading] = useState(false);
    const getAttributes = () => {
        axios("admin/attribute_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributes(response.data.attributes);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };

    const getAttributeValues = (e) => {
        setAttributeId(e.target.value);
        let formdata = new FormData();
        formdata.append("attribute_id", e.target.value);
        axios("admin/attribute_values_by_attribute", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    props.handleUpdateAttributeValues(props.index, response.data.data)
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };



    const handleChangeAttributeValue = (e) => {
        props.handleAttributeSelect(props.attribute.id == 0 ? attributeId : props.attribute.id, e.target.value);
    }



    useEffect(() => {
        getAttributes();
    }, []);


    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                <select className="form-control form-control-lg form-select" id={attributeId} onChange={(e) => getAttributeValues(e)} >
                    <option value={0}>Select Attribute</option>
                    {attributes.map((attribute) => {
                        return (
                            <option value={attribute.id} selected={props.attribute.id == attribute.id} >{attribute.name}</option>
                        )
                    })}

                </select>
            </Grid>
            <Grid item xs={5}>
                <select className="form-control form-control-lg form-select" onChange={handleChangeAttributeValue}  >
                    <option selected value={0} >Select Attribute Value</option>
                    {props.attributeValues.map((value) => {
                        return (
                            <option selected={props.attribute.pivot && props.attribute.pivot.attribute_value_id == value.id} value={value.id}>{value.name}</option>
                        )
                    })}
                </select>
            </Grid>
            <Grid item xs={2}>
                <span className="btn btn-sm btn-danger" title="Delete" onClick={() => props.removeElement(props.index)} ><i className="fi fi-rr-trash"></i></span>
            </Grid>
        </Grid>
    )
}

export default AttributeSelectorEdit