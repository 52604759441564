import React, { useEffect, useState } from "react";
import axios from "../../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ErrorHandler } from "../../../ui/ErrorHandler";
import "./AttributeSet.css";

import 'react-dropdown-tree-select/dist/styles.css';
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function EditAttributeSet(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [attributeSet, setAttributeSet] = useState({});
    const [attributeSetId, setAttributeSetId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const getAttributes = (array) => {
        axios("admin/attribute_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setRight(response.data.attributes.filter(item1 => !array.some(item2 => item1.id === item2.id)));
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    useEffect(() => {
        getAttributeSetDetails();
    }, [props.match.params.id]);
    const getAttributeSetDetails = () => {
        axios(`admin/attribute_sets/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributeSet(response.data.data.attribute_set);
                    setLeft(response.data.data.attribute_set.attributes);
                    getAttributes(response.data.data.attribute_set.attributes);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", attributeSet.name);
        formdata.append("name_ar", attributeSet.name_ar);
        formdata.append("attribute_ids", left.map(item => item.id).join(','));
        formdata.append("id", attributeSetId);
        axios("admin/attribute_set_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/attribute-sets",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.id}-label`;
                    return (
                        <ListItemButton
                            key={value}
                            role="listitem"
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/attribute-sets"
                        >
                            Attribute Sets
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="#"
                            aria-current="page"
                        >
                            Edit Attribute Set
                        </Link>
                    </Breadcrumbs>
                    <hr></hr>
                </div>
                <div class="container-fluid  mt-5">

                    <div className="row">
                        <div className="col-md-12">
                            {Object.keys(attributeSet).length != 0 && (
                                <form method="POST" className="attribute-form">
                                    <h3 className="edit-title">Edit Attribute Set <i class="fi fi-rr-file-edit"></i></h3>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setAttributeSet({
                                                ...attributeSet,
                                                name: e.target.value,
                                            })} value={attributeSet.name} name="name" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setAttributeSet({
                                                ...attributeSet,
                                                name_ar: e.target.value,
                                            })} value={attributeSet.name_ar} name="name_ar" />
                                        </Grid>
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">


                                            <Grid item className="border col-md-4"> <h4 className="role-permission-title">Selected Attributes</h4>{customList(left)}</Grid>
                                            <Grid item>
                                                <Grid container direction="column" alignItems="center" className="border-0">
                                                    <Button
                                                        sx={{ my: 0.5 }}
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={handleAllRight}
                                                        disabled={left.length === 0}
                                                        aria-label="move all right"
                                                    >
                                                        <i class="fi fi-rr-angle-double-small-right"></i>
                                                    </Button>
                                                    <Button
                                                        sx={{ my: 0.5 }}
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={handleCheckedRight}
                                                        disabled={leftChecked.length === 0}
                                                        aria-label="move selected right"
                                                    >
                                                        <i class="fi fi-rr-angle-small-right"></i>
                                                    </Button>
                                                    <Button
                                                        sx={{ my: 0.5 }}
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={handleCheckedLeft}
                                                        disabled={rightChecked.length === 0}
                                                        aria-label="move selected left"
                                                    >
                                                        <i class="fi fi-rr-angle-small-left"></i>
                                                    </Button>
                                                    <Button
                                                        sx={{ my: 0.5 }}
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={handleAllLeft}
                                                        disabled={right.length === 0}
                                                        aria-label="move all left"
                                                    >
                                                        <i class="fi fi-rr-angle-double-small-left"></i>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item className="border col-md-4"><h4 className="role-permission-title">All Attributes</h4>{customList(right)}</Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default EditAttributeSet;