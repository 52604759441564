import { useContext, useEffect } from "react";
import { AuthContext } from "../Context/AuthContext";
const IsAllowed = (permission) => {
  const { permissions, role } = useContext(AuthContext);
  return role == "Super Admin"
    ? true
    : (permissions || []).find((p) => p.name === permission)
    ? true
    : false;
};
export default IsAllowed;
