import React, { useEffect, useState } from "react";
import axios from "../../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { ErrorHandler } from "../../../ui/ErrorHandler";
import "./AttributeValue.css";
import SelectSearch from 'react-select-search';
function EditAttributeValue(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [attributeValue, setAttributeValue] = useState({});
    const [attributes, setAttributes] = useState([]);
    const [attributeValueId, setAttributeValueId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getAttributeValueDetails();
    }, [props.match.params.id]);
    const getAttributeValueDetails = () => {
        axios(`admin/attribute_values/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttributeValue(response.data.data.attribute_value);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", attributeValue.name);
        formdata.append("name_ar", attributeValue.name_ar);
        formdata.append("attribute_id", attributeValue.attribute_id);
        formdata.append("id", attributeValueId);
        axios("admin/attribute_value_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/attribute-values",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/attribute-values"
                        >
                            Attribute Values
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="#"
                            aria-current="page"
                        >
                            Edit Attribute Value
                        </Link>
                    </Breadcrumbs>
                    <hr></hr>
                </div>
                <div class="container-fluid  mt-5">

                    <div className="row">
                        <div className="col-md-12">
                            {Object.keys(attributeValue).length != 0 && (
                                <form method="POST" className="attribute-form">
                                    <h3 className="edit-title">Edit Attribute Value <i class="fi fi-rr-file-edit"></i></h3>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setAttributeValue({
                                                ...attributeValue,
                                                name: e.target.value,
                                            })} value={attributeValue.name} name="name" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setAttributeValue({
                                                ...attributeValue,
                                                name_ar: e.target.value,
                                            })} value={attributeValue.name_ar} name="name_ar" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectSearch options={attributes} id="customerName" name="customerName" value={attributeValue.attribute_id} onChange={(e) => setAttributeValue({
                                                ...attributeValue,
                                                attribute_id: e,
                                            })} search placeholder="Choose your Attribute" getOptions={(query) => {

                                                return new Promise((resolve, reject) => {
                                                    let formdata = new FormData();
                                                    formdata.append("search", query);
                                                    axios("admin/attribute_list_by_keyword", {
                                                        method: "POST",
                                                        data: formdata,
                                                        headers: {
                                                            "Content-Type": "application/x-www-form-urlencoded",
                                                            Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
                                                        },
                                                    })
                                                        .then((response) => {
                                                            setAttributes(response.data.attributes);
                                                        })
                                                        .catch(reject);

                                                });
                                            }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default EditAttributeValue;