import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
function CustomAutocomplete({ options, label, onChange, selectedOption }) {
    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => {
                return option && option.name;
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={(event, value) => onChange(value)}
            value={selectedOption}
        />
    );
}

export default CustomAutocomplete;
