import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import axios from "../Utils/Axios";
import { ErrorHandler } from '../ui/ErrorHandler';
import { useAlert } from "react-alert";

function ImageEditor({ productImages, getProductImages }) {
    const [loading, setLoading] = useState(false);

    const alert = useAlert();
    const deleteImage = (id) => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("id", id);
        axios("admin/product_image_delete", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    getProductImages();
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div class="container-fluid">
            <div className="mt-2">
                <div className="table-responsive">
                    <table className="table table-bordered  ">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Image</th>
                                <th>Alt</th>
                                <th>Sort order</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productImages.length > 0 ? productImages.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td><img src={value.image} width={50}></img></td>
                                        <td>{value.alt_url}</td>
                                        <td>{value.sort_order}</td>
                                        <td>
                                            <span className="btn btn-sm btn-danger" title="Delete" onClick={() => deleteImage(value.id)}><i className="fi fi-rr-trash"></i></span>
                                        </td>
                                    </tr>
                                );
                            }) : (<td colSpan={5} className="text-center"> No Images</td>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ImageEditor