import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory, Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../ui/ErrorHandler";
import SelectSearch from 'react-select-search';
import Faq from "../FAQ/Faq";
import "./Services.css";
import PageSection from "../PageSection/PageSection";


function EditService(props) {
    const alert = useAlert();
    const history = useHistory();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [service, setService] = useState({});
    const [seo, setSeo] = useState({});
    const [serviceId, setServiceId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );

    const types = [
        { name: 'Dynamic Content ', value: '0' },
        { name: 'Static Content ', value: '1' }
    ];
    useEffect(() => {
        getServiceDetails();
        getParentServices();
    }, [props.match.params.id]);
    const getServiceDetails = () => {
        axios(`admin/services/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setService(response.data.data.service);
                    setSeo(response.data.data.seo[0] ?? {});
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", service.name);
        formdata.append("name_ar", service.name_ar);
        formdata.append("slug", service.slug);
        formdata.append("parent_id", service.parent_id);
        formdata.append("description", service.description);
        formdata.append("description_ar", service.description_ar);
        formdata.append("short_description", service.short_description);
        formdata.append("short_description_ar", service.short_description_ar);
        formdata.append("video_url", service.video_url);
        formdata.append("page_type", service.page_type);
        formdata.append("parent_id", service.parent_id);
        formdata.append("meta_title", seo.meta_title);
        formdata.append("meta_description", seo.meta_description);
        formdata.append("meta_keyword", seo.meta_keyword);
        formdata.append("store_id", 1);
        formdata.append("lang_id", 1);

        formdata.append("id", serviceId);
        axios("admin/service_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    history.push({
                        pathname: "/services",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const getParentServices = () => {
        axios("admin/parent_services", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    response.data.data.unshift({ name: 'No parent', value: '0' });
                    setServices(response.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(({ response }) => {
                setLoading(false);
            });
    };
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/services"
                    >
                        Services
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to="#"
                        aria-current="page"
                    >
                        Edit Services
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid  mt-5">
                <div className="row">
                    <div className="col-md-8">
                        {Object.keys(service).length != 0 && (
                            <form method="POST" className="service-form">
                                <h3 className="edit-title">Edit Services <i class="fi fi-rr-file-edit"></i></h3>
                                <hr></hr>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SelectSearch options={services} id="parent" value={service.parent_id} onChange={(e) => setService({
                                            ...service,
                                            parent_id: e,
                                        })}
                                            name="parent" search fuzzySearch placeholder="Choose your Parent Service" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectSearch options={types} id="page_type" value={service.page_type} onChange={(e) => setService({
                                            ...service,
                                            page_type: e,
                                        })}
                                            name="page_type" search fuzzySearch placeholder="Choose your Page Type" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setService({
                                            ...service,
                                            name: e.target.value,
                                        })} value={service.name} name="name" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setService({
                                            ...service,
                                            name_ar: e.target.value,
                                        })} value={service.name_ar} name="name_ar" />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <label className="label-description">
                                            Description<br></br>
                                            <TextEditor defaultValue={service.description} onChange={(e) => setService({
                                                ...service,
                                                description: e,
                                            })} />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Description Arabic<br></br>
                                            <TextEditor defaultValue={service.description_ar} onChange={(e) => setService({
                                                ...service,
                                                description_ar: e,
                                            })} />
                                        </label>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Short Description<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setService({
                                                ...service,
                                                short_description: e.target.value,
                                            })} value={service.short_description} name="short_description" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label className="label-description">
                                            Short Description Arabic<br></br>
                                            <textarea className="description" rows={4} cols={40} onChange={(e) => setService({
                                                ...service,
                                                short_description_ar: e.target.value,
                                            })} value={service.short_description_ar} name="short_description_ar" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="label-video-url">
                                            Video url<br></br>
                                            <textarea className="description" rows={2} cols={80} onChange={(e) => setService({
                                                ...service,
                                                video_url: e.target.value,
                                            })} value={service.video_url} name="video_url" />
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <label>
                                            Upload Image<br></br>
                                            <input type="file" className="upload-img" ></input>
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <label>
                                            Upload Image Arabic<br></br>
                                            <input type="file" className="upload-img" ></input>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </div>
                    <div className="col-md-4">

                        <form method="POST" className="service-form">
                            <h3 className="edit-title"> SEO Contents  <i class="fi fi-rr-file-edit"></i> </h3>
                            <hr></hr>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField id="outlined-basic" fullWidth label="Seo url" variant="outlined" onChange={(e) => setService({
                                        ...service,
                                        slug: e.target.value,
                                    })} value={service.slug} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="outlined-basic" fullWidth label="Meta Title" variant="outlined" onChange={(e) => setSeo({
                                        ...seo,
                                        meta_title: e.target.value,
                                    })} value={seo.meta_title} />
                                </Grid>
                                <Grid item xs={12}>
                                    <label className="label-description">
                                        Meta Description<br></br>
                                        <textarea className="description" rows={4} cols={40} onChange={(e) => setSeo({
                                            ...seo,
                                            meta_description: e.target.value,
                                        })} value={seo.meta_description} />
                                    </label>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="outlined-basic" fullWidth label="Meta Keyword" variant="outlined" onChange={(e) => setSeo({
                                        ...seo,
                                        meta_keyword: e.target.value,
                                    })} value={seo.meta_keyword} />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button> */}
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
            <div class="container-fluid  mt-5">
                <Faq page={'service'} pageId={serviceId}></Faq>
            </div>
            <div class="container-fluid  mt-5">
                <PageSection page={'service'} pageId={serviceId}></PageSection>
            </div>
        </div>
    );
}
export default EditService;