import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import SelectSearch from 'react-select-search';
import { ErrorHandler } from "../../ui/ErrorHandler";
import "./ProductEnquiry.css";

function ProductEnquiryDetail(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [enquiry, setEnquiry] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [users, setUsers] = useState([]);
    const [statusHistory, setStatusHistory] = useState([]);
    const [status, setStatus] = useState(0);
    const [assignedTo, setAssignedTo] = useState(0);
    const [comment, setComment] = useState('');
    const [enquiryId, setEnquiryId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getEnquiryDetails();
        getStatuses();
        getUsers();
    }, [props.match.params.id]);
    const getEnquiryDetails = () => {
        getStatusHistory();
        axios(`admin/product_enquiries/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setEnquiry(response.data.data);
                    setStatus(response.data.data.status_id);
                    setAssignedTo(response.data.data.assigned_to);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const getStatusHistory = () => {
        axios(`admin/product_enquiry_status_history/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setStatusHistory(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const getStatuses = () => {
        axios("admin/all_statuses", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setStatuses(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const getUsers = () => {
        axios("admin/all_admins", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setUsers(response.data.data);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };

    const updateStatus = () => {

        setLoading(true);
        let formData = new FormData();
        formData.append("enquiry_id", enquiryId);
        formData.append("comment", comment);
        formData.append("status_id", status);

        axios(`admin/product_enquiry_update_status`, {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getEnquiryDetails();
                    // history.push({
                    //     pathname: "/services",
                    //     state: { data: true },
                    // });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };


    const updateAssignee = () => {

        setLoading(true);
        let formData = new FormData();
        formData.append("enquiry_id", enquiryId);
        formData.append("assigned_to", assignedTo);

        axios(`admin/product_enquiry_assign_enquiry`, {
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message);
                    getEnquiryDetails();
                    // history.push({
                    //     pathname: "/services",
                    //     state: { data: true },
                    // });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/enquiries"
                        >
                            Enquiries
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="#"
                            aria-current="page"
                        >
                            Enquiry Detail
                        </Link>
                    </Breadcrumbs>
                    <hr></hr>
                </div>
                <div class="container-fluid  mt-5">

                    <div className="row">
                        <div className="col-md-8">
                            {Object.keys(enquiry).length != 0 && (
                                <form method="POST" className="category-form">
                                    <h3 className="edit-title">Enquiry Detail <i class="fi fi-rr-file-edit"></i></h3>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        {/* <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setEnquiry({
                                                ...enquiry,
                                                name: e.target.value,
                                            })} value={category.name} name="name" />
                                        </Grid> */}
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth disabled label="Enquiry Id" variant="outlined" value={enquiry.enquiry_id} name="enquiry_id" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth disabled label="Name" variant="outlined" value={enquiry.name} name="name" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth disabled label="Email" variant="outlined" value={enquiry.email} name="email" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth disabled label="Mobile" variant="outlined" value={enquiry.phone} name="phone" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth disabled label="Product" variant="outlined" value={enquiry.product_id == 0 ? '' : enquiry.product.name} name="product" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth disabled label="Qty" variant="outlined" value={enquiry.qty} name="qty" />
                                        </Grid>

                                    </Grid>
                                </form>
                            )}



                            <div class="col-md-12 status-box border p-3 mt-3">
                                <form method="POST" class="row align-items-center">
                                    <div class="form-group col-md-5">
                                        <label for="statuses">Status</label>
                                        <SelectSearch options={statuses} id="statuses" name="statuses" onChange={(e) =>
                                            setStatus(e)
                                        } value={status} search placeholder="Choose your Status" />
                                    </div>
                                    <div class="form-group col-md-5">
                                        <label for="comment">Comment:</label>
                                        <textarea id="comment" name="comment" class="form-control" onChange={(e) => setComment(e.target.value)}  >{comment}</textarea>
                                    </div>
                                    <div class="form-group text-center mb-0 col-md-2">

                                        <button type="button" class="btn btn-info btn-sm " onClick={updateStatus}  >Change</button>

                                    </div>
                                </form>
                            </div>



                        </div>
                        <div className="col-md-4">


                            <div class="col-md-12 status-box border p-3 mb-5">
                                <form method="POST">
                                    <div class="form-group">
                                        <label for="statuses">Assigned To</label>
                                        <SelectSearch options={users} id="assigned_to" name="assigned_to" onChange={(e) =>
                                            setAssignedTo(e)
                                        } value={assignedTo} search placeholder="Choose your User" />
                                    </div>
                                    <div class="form-group text-center mb-0">

                                        <button type="button" class="btn btn-info btn-sm " onClick={updateAssignee}   >Change</button>

                                    </div>
                                </form>
                            </div>
                            <h6>Status history</h6>
                            <ul class="timeline">
                                {statusHistory && statusHistory.map((value, index) => {
                                    return (
                                        <>
                                            <li className={"alert alert-" + value.status.style}>
                                                <h5>{value.status.name}</h5>
                                                <div class="mb-0 d-flex justify-content-between"><span><i className="fa fa-calendar"></i> {value.created_at}</span></div>
                                                <p class="w-100 timeline-text">{value.comment}</p>
                                                <p><i className="fa fa-user"></i> {value.assigned_to.first_name}</p>
                                            </li>
                                        </>
                                    )
                                })}

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default ProductEnquiryDetail;