import React, { useEffect, useState } from "react";
import axios from "../../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useHistory } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import BLink from '@mui/material/Link';
import { useAlert } from "react-alert";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { ErrorHandler } from "../../../ui/ErrorHandler";
import "./Attribute.css";
import TextEditor from "../../../ui/TextEditor";
function EditAttribute(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [attribute, setAttribute] = useState({});
    const [attributeId, setAttributeId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getAttributeDetails();
    }, [props.match.params.id]);
    const getAttributeDetails = () => {
        axios(`admin/attributes/${props.match.params.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setAttribute(response.data.data.attribute);
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", attribute.name);
        formdata.append("name_ar", attribute.name_ar);
        formdata.append("id", attributeId);
        axios("admin/attribute_update", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                    history.push({
                        pathname: "/attributes",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div>
            <div class="container-fluid">
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/attributes"
                        >
                            Attributes
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            to="#"
                            aria-current="page"
                        >
                            Edit Attribute
                        </Link>
                    </Breadcrumbs>
                    <hr></hr>
                </div>
                <div class="container-fluid  mt-5">

                    <div className="row">
                        <div className="col-md-12">
                            {Object.keys(attribute).length != 0 && (
                                <form method="POST" className="attribute-form">
                                    <h3 className="edit-title">Edit Attribute <i class="fi fi-rr-file-edit"></i></h3>
                                    <hr></hr>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setAttribute({
                                                ...attribute,
                                                name: e.target.value,
                                            })} value={attribute.name} name="name" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setAttribute({
                                                ...attribute,
                                                name_ar: e.target.value,
                                            })} value={attribute.name_ar} name="name_ar" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <button type="button" onClick={handleSubmit} className="btn btn-primary  "><i class="fi fi-rr-refresh"></i> Update</button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default EditAttribute;