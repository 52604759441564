import React, { useEffect, useState } from "react";
import axios from "../../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAlert } from "react-alert";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { ErrorHandler } from "../../../ui/ErrorHandler";
import { Link } from "react-router-dom";
import SelectSearch from 'react-select-search';


function AddAttributeValue() {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [attributeId, setAttributeId] = useState(0);
    const [name, setName] = useState('');
    const [nameAr, setNameAr] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("name_ar", nameAr);
        formdata.append("attribute_id", attributeId);
        
        axios("admin/attribute_value_save", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                } else {
                    setLoading(false);
                    ErrorHandler(alert, response.status, response.data.message)
                }
            })
            .catch(({ response }) => {
                setLoading(false);
                ErrorHandler(alert, response.status, response.data.message)
            });
    };
    return (
        <div class="container-fluid">
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/attribute-values"
                    >
                        Attribute Values
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        to="#"
                        aria-current="page"
                    >
                        Add Attribute Value
                    </Link>
                </Breadcrumbs>
                <hr></hr>
            </div>
            <div class="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <form method="POST" className="attribute-form">

                            <h3 className="edit-title">Add Attribute Value <i class="fi fi-rr-add"></i> </h3>
                            <hr></hr>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} value={name} name="name" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" fullWidth label="Name Arabic" variant="outlined" onChange={(e) => setNameAr(e.target.value)} value={nameAr} name="name_ar" />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectSearch options={attributes} id="customerName" name="customerName" value={attributeId} onChange={(e) => setAttributeId(e)} search placeholder="Choose your Attribute" getOptions={(query) => {

                                        return new Promise((resolve, reject) => {
                                            let formdata = new FormData();
                                            formdata.append("search", query);
                                            axios("admin/attribute_list_by_keyword", {
                                                method: "POST",
                                                data: formdata,
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded",
                                                    Authorization: "Bearer " + JSON.parse(localStorage.getItem("data")),
                                                },
                                            })
                                                .then((response) => {
                                                    setAttributes(response.data.attributes);
                                                })
                                                .catch(reject);

                                        });
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary"><i class="fi fi-rr-layer-plus"></i> Add</button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default AddAttributeValue;